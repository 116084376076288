import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Scene } from '@babylonjs/core/scene';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';



export abstract class AbstractIndicatorGroup {

    constructor(indicSrcMesh: Mesh, protected scene: Scene) {
        this.indicSrcMesh = indicSrcMesh;
        this.indicSrcMesh.setEnabled(false);
    }

    protected indicSrcMesh: Mesh;
    protected groupVisible: boolean = true;
    protected indicMeshesState: { [meshName: string]: { mesh: Mesh, visible: boolean } } = {};

    public setGroupVisible(visible: boolean, instant: boolean) {
        if (visible == this.groupVisible) return;
        this.groupVisible = visible;
        this.syncIndicMeshesVisibility(instant, 0.2);
    }

    public setIndicatorVisible(name: string, visible: boolean) {
        this.indicMeshesState[name].visible = visible;
        this.syncIndicMeshesVisibility(false, 0.5);
    }

    public addIndicator(
        name: string, pos: Vector3, rot: Vector3, parent?: Mesh, visible?: boolean
    ): Mesh {
        const im = this.stateCreateIndicMesh(name, pos, rot, parent, visible);
        this.syncIndicMeshesVisibility(true,0);
        return im;
    }

    /**
     * more efficient for adding many at once
     */
    public addIndicators(indicators: Array<{
        name: string, pos: Vector3, rot: Vector3, parent?: Mesh, visible?: boolean
    }>): Array<Mesh> {

        const r = [];
        for (const i of indicators) {
            const im = this.stateCreateIndicMesh(i.name, i.pos, i.rot, i.parent, i.visible);
            r.push(im);
        }
        this.syncIndicMeshesVisibility(true,0);
        return r;
    }

    private stateCreateIndicMesh(
        name: string, pos: Vector3, rot: Vector3, parent?: Mesh, visible: boolean = true
    ): Mesh {
        const im = this.indicSrcMesh.clone(name);
        im.setEnabled(true);
        im.position = pos;
        im.rotation = rot;
        if (parent) im.parent = parent;
        this.indicMeshesState[im.name] = { mesh: im, visible };
        return im;
    }

    private syncIndicMeshesVisibility(instant: boolean, tweenDuration: number) {
        if (instant) {
            for (const indicMeshName in this.indicMeshesState) {
                const indicMeshState = this.indicMeshesState[indicMeshName];
                if (!indicMeshState.visible || !this.groupVisible) {
                    indicMeshState.mesh.visibility = 0;
                    indicMeshState.mesh.setEnabled(false);
                } else {
                    indicMeshState.mesh.visibility = 1;
                    indicMeshState.mesh.setEnabled(true);
                }
            }
        } else {
            for (const indicMeshName in this.indicMeshesState) {
                const indicMeshState = this.indicMeshesState[indicMeshName];
                const indicMesh = indicMeshState.mesh;
                const visible = indicMeshState.visible;
                if (!visible || !this.groupVisible) {
                    TweenLite.to(indicMesh, tweenDuration, {
                        visibility: 0,
                        onComplete: this.utilSetMeshEnabledByVis,
                        onCompleteParams: [indicMesh]
                    });
                } else {
                    TweenLite.to(indicMesh, tweenDuration, { visibility: 1 });
                    indicMesh.setEnabled(true);
                }
            }
        }
    }

    protected utilSetMeshEnabledByVis(mesh: Mesh) {
        if (mesh.visibility) mesh.setEnabled(true);
        else mesh.setEnabled(false);
    }

}
