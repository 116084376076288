export const annoFunction = (annotation:HTMLElement,hText:string,pText:string,closeBTNClassName:string) => {
    var annotation = document.getElementById(`${annotation}`);

    if (annotation) {
        annotation.parentNode.removeChild(annotation);
    }

    annotation = document.createElement("div");

    annotation.id = `${annotation}`;
    annotation.className = "label-component";
    annotation.style.transform = "translate3d(40vw, 30px, 0px)";
    var hName = document.createElement("h2")
    var pName = document.createElement("p");
    hName.textContent = hText;
    pName.textContent = pText;
    var closeBtn = document.createElement("a")
    closeBtn.textContent = "✕"
    closeBtn.className = closeBTNClassName
    annotation.appendChild(hName);
    annotation.appendChild(pName);
    annotation.appendChild(closeBtn)
    document.body.appendChild(annotation);
    annotation.classList.add("is-hidden");
    
    const svgLineCornerSize = 30;
    const htmlElemString = `
        <svg class="standard-annotation-corner-line-svg">
        <line x1="0" y1="0" x2="${svgLineCornerSize}" y2="${svgLineCornerSize}"
            style="stroke:rgba(255,255,255,0.7);stroke-width:0.9"
        />
    </svg>
`;

    let annoLine: HTMLElement;
    let svgLine: SVGLineElement;

    const utilDiv = document.createElement("div");
    utilDiv.innerHTML = htmlElemString.trim();
    annoLine = utilDiv.firstChild as HTMLElement;
    svgLine = annoLine.querySelector(".standard-annotation-corner-line-svg line") as SVGLineElement;
    annotation.appendChild(annoLine);

    return{
        'annotation': annotation,
        'hName': hName,
        'pName':pName,
        'hText':hText,
        'pText':pText,
        'closeBtn':closeBtn,
        'annoLine':annoLine
    } 
    
}