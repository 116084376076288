import { AbstractIndicatorGroup } from "./abstractIndicatorGroup";
import { Scene } from '@babylonjs/core/scene';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Material } from '@babylonjs/core/Materials/material';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { SphereBuilder } from "@babylonjs/core/Meshes/Builders/sphereBuilder";
import { TorusBuilder } from '@babylonjs/core/Meshes/Builders/torusBuilder';


interface IndicatorShapeDefinition {
    /**
     * (internal, do not use)
     * @hidden
     */
    _generateIndicSrcMesh(scene: Scene): Mesh;
}

export class ShapeIndicatorGroup extends AbstractIndicatorGroup {

    constructor(shapeDefinition: IndicatorShapeDefinition, scene: Scene) {
        const indicSrcMesh = shapeDefinition._generateIndicSrcMesh(scene);
        super(indicSrcMesh, scene);
    }

}

export class SphereIndicatorShapeDefinition implements IndicatorShapeDefinition {
    constructor(
        protected diameter: number,
        protected color?: Color3,
        protected alpha?: number,
        protected material?: Material,
        protected segments?: number,
        protected pickable?: boolean
    ) { }

    /**
     * (internal, do not use)
     * @hidden
     */
    _generateIndicSrcMesh(scene: Scene): Mesh {
        const indicSrcMesh = SphereBuilder.CreateSphere("SphereIndicatorShapeDefinition_generated_indicSrcMesh", {
            diameter: this.diameter, segments: this.segments
        }, scene);
        indicSrcMesh.isPickable = Boolean(this.pickable);

        if (this.material) {
            indicSrcMesh.material = this.material;
        } else if (this.color) {
            const mat = new StandardMaterial("SphereIndicatorShapeDefinition_generated_indicSrcMesh_material", scene);
            mat.diffuseColor.copyFromFloats(0, 0, 0);
            mat.specularColor.copyFromFloats(0, 0, 0);
            mat.emissiveColor.copyFrom(this.color);
            if (this.alpha != undefined) mat.alpha = this.alpha;
            indicSrcMesh.material = mat;
        }

        return indicSrcMesh;
    }

}

export class TorusIndicatorShapeDefinition implements IndicatorShapeDefinition {
    constructor(
        protected diameter: number,
        protected thickness: number,
        protected color?: Color3,
        protected alpha?: number,
        protected material?: Material,
        protected tessellation?: number,
        protected pickable?: boolean,
        protected locked?: boolean,
        protected clickable?: boolean
    ) { }

    /**
     * (internal, do not use)
     * @hidden
     */
    _generateIndicSrcMesh(scene: Scene): Mesh {
        var indicSrcMesh:Mesh;
        var btnMesh:Mesh;
        const tempSrcMesh = TorusBuilder.CreateTorus("TorusIndicatorShapeDefinition_generated_indicSrcMesh", {
            diameter: this.diameter, thickness: this.thickness, tessellation: this.tessellation
        }, scene);
        

        if (this.material) {
            tempSrcMesh.material = this.material;
        } else if (this.color) {
            const mat = new StandardMaterial("TorusIndicatorShapeDefinition_generated_indicSrcMesh_material", scene);
            mat.diffuseColor.copyFromFloats(0, 0, 0);
            mat.specularColor.copyFromFloats(0, 0, 0);
            mat.emissiveColor.copyFrom(this.color);
            if (this.alpha != undefined) mat.alpha = this.alpha;
            tempSrcMesh.material = mat;
        }
        console.log("locked?", this.locked)
        if (this.clickable) {
            const btnMesh = SphereBuilder.CreateSphere("SphereIndicatorShapeDefinition_generated_indicSrcMesh", {
                diameter: this.diameter, segments: this.tessellation
            }, scene);
            btnMesh.isPickable = Boolean(this.pickable);
            const btMat = new StandardMaterial("SphereIndicatorShapeDefinition_generated_indicSrcMesh_material", scene);
            
            btMat.alpha = .0000000001;
            btnMesh.material = btMat;
           // tempSrcMesh.isPickable = false;
            //btnMesh.parent = tempSrcMesh;

            indicSrcMesh  = Mesh.MergeMeshes([tempSrcMesh, btnMesh], true, true, undefined, false, true) as Mesh;
        } else {
            indicSrcMesh = tempSrcMesh;
       }
        if (this.locked) {
            indicSrcMesh.billboardMode = Mesh.BILLBOARDMODE_ALL
        }
        indicSrcMesh.isPickable = Boolean(this.pickable);
        return indicSrcMesh;
    }

}
