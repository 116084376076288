import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { CreateSceneClass } from "../createScene";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { EnvironmentHelper } from "@babylonjs/core/Helpers/environmentHelper";
import { AbstractIndicatorGroup } from "../lib/indication/abstractIndicatorGroup";



// required imports
import "@babylonjs/core/Loading/loadingScreen";
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Materials/standardMaterial";
import "@babylonjs/core/Materials/Textures/Loaders/envTextureLoader";
import "@babylonjs/core/Animations/animatable";


// digital assets
import sceneGLB from "../../assets/glb/thor.glb";
import skyEnvironment from "../../assets/environment/sky.env"
import { AbstractMesh, ActionManager, Color3, EasingFunction, ExecuteCodeAction, Mesh, PointerEventTypes, PointerInfo, SineEase, StandardMaterial, Animation, Curve3, Path3D, CubicEase, DefaultLoadingScreen, MeshBuilder } from "@babylonjs/core";
import { ShapeIndicatorGroup, TorusIndicatorShapeDefinition } from "../lib/indication/shapeIndicatorGroup";
import { annoFunction } from "../lib/annotations/annoFunction";
import { setupToolbox } from "../scene/toolbox";
import { HideLoadingUI, showLoadingUI } from "../scene/CustomLoadingScreen";
import { textInHotspot } from "../lib/indication/textInHotspot";
import { changeCamera } from "../lib/cameras/cameraChange";


//INIT LOADING LAYER 
showLoadingUI()
HideLoadingUI();


export class AppModeController implements CreateSceneClass {
    private _createScene = async (
        engine: Engine,
        canvas: HTMLCanvasElement
    ): Promise<Scene> => {
        engine.displayLoadingUI();
        const scene = new Scene(engine);

        void Promise.all([
            import("@babylonjs/core/Debug/debugLayer"),
            import("@babylonjs/inspector"),
        ]).then((_values) => {
            console.log(_values);
            window.addEventListener("keydown", (e) => {
                if (e.key === 'q') {
                    if (scene.debugLayer.isVisible()) {
                        scene.debugLayer.hide();
                    } else {
                        scene.debugLayer.show({
                            handleResize: true,
                            overlay: true,
                            globalRoot: document.getElementById("#root") || undefined,
                        });
                    }
                }
            });

            setupToolbox(scene);
        });

        ///////////////////////
        // INITIAL VARIABLES //
        ///////////////////////

        var backButton = document.getElementById("backButton") as HTMLElement;
        

        ////////////
        // CAMERA //
        ////////////

        const mainCamera = new ArcRotateCamera(
            "mainCamera",
            1.2,
            Math.PI / 3,
            10,
            new Vector3(0, 0, 0),
            scene
        );
        mainCamera.minZ = 0.1;
        mainCamera.maxZ = 1000;
        mainCamera.lowerRadiusLimit = 2;
        mainCamera.upperRadiusLimit = 20;
        mainCamera.wheelPrecision = 50;

        // // This targets the camera to scene origin
        mainCamera.setTarget(Vector3.Zero());

        // // This attaches the camera to the canvas
        mainCamera.attachControl(canvas, true);

        mainCamera.useFramingBehavior = true;

        /// POINTS FOR CAMERA CHANGE ANIM FUNCTION 
        let dummyIndic = { alpha: 1.72, beta: 0.9, radius: 4, x: -.5, y: 1, z: 1 };
        let zeroPoint = { alpha: 1.2, beta: Math.PI / 3, radius: 10, x: 0, y: 0, z: 0 };
        var anotherDummyIndic = { alpha: 1.897, beta: 1.217, radius: 7, x: 1.5, y: 1, z: -1 };




        // load the environment file
        scene.environmentTexture = new CubeTexture(skyEnvironment, scene);

        // if not setting the envtext of the scene, we have to load the DDS module as well
        new EnvironmentHelper({
            skyboxSize: 40,
            skyboxTexture: skyEnvironment,
            createGround: false
        }, scene);

        // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        const light = new HemisphericLight(
            "light",
            new Vector3(0, 1, 0),
            scene
        );

        // Default intensity is 1. Let's dim the light a small amount
        light.intensity = 0.7;

        const importResult = await SceneLoader.ImportMeshAsync(
            "",
            "",
            sceneGLB,
            scene,
            undefined,
            ".glb"
        );

        // just scale it so we can see it better
        importResult.meshes[0].scaling.scaleInPlace(0.5);

        // box created for mesh action testing , remove if you wanted 
        let box = MeshBuilder.CreateBox("box", { size: 0.3 })
        box.position.y = 3;
        console.log(box.position)




        /////////////////
        // INDIC GROUP //
        /////////////////
        let indicGroup: AbstractIndicatorGroup;
        let _dummyIndic: Mesh;
        let _anotherDummyIndic: Mesh;

        const indicMaterial = new StandardMaterial("indic_material");
        indicMaterial.diffuseColor = Color3.FromHexString("#34B1E7");
        indicMaterial.emissiveColor = Color3.FromHexString("#34B1E7");
        indicMaterial.specularColor.copyFromFloats(0, 0, 0);
        indicMaterial.alpha = 0.8;

        indicGroup = new ShapeIndicatorGroup(
            new TorusIndicatorShapeDefinition(.3, .03, Color3.FromHexString("#34B1E7"), 1, indicMaterial, 64, false, true, true), scene
        );


        // main indicators
        const indicMeshes = indicGroup.addIndicators([
            {
                name: "dummyIndic",
                pos: new Vector3(-.5, 1, 1),
                rot: new Vector3(Math.PI / 2, 0, 0),
            },
            {
                name: "anotherDummyIndic",
                pos: new Vector3(1.5, 1, -1),
                rot: new Vector3(Math.PI / 2, 0, 0),
            }
        ]);


        _dummyIndic = indicMeshes[0];
        _anotherDummyIndic = indicMeshes[1];


        for (const m of indicMeshes) {
            (m as any).isPickable = true;
        }
        const dummyIndicText = textInHotspot("1", _dummyIndic, 300, scene)
        const anotherDummyIndicText = textInHotspot("2", _anotherDummyIndic, 300, scene)
        console.log(dummyIndicText)

        //////////////////////////////////
        //ANNOTATIONS PROTOTYPE ? GROUP //
        ////////////////////////////////

        let anno: HTMLElement;
        let h = "pierwsze anno";
        let p = "work work work"
        let closeClass1 = "anno1close"
        const newAnno = annoFunction(anno, h, p, closeClass1)

        let anno2: HTMLElement;
        let h2 = "drugie anno";
        let p2 = "work work work"
        let closeClass2 = "anno2close"
        const newAnno2 = annoFunction(anno2, h2, p2, closeClass2);



        var anno1ScreenCoords;
        var anno2ScreenCoords;

        scene.onAfterRenderObservable.add(() => {

            ///////////////////////////////////////
            ////////////HIDE LOADING LAYER  ////////
            ///////////////////////////////////////
            engine.hideLoadingUI();

            ///////////////////////////////////////
            ////////////POSITIONING OF ANNO ////////
            ///////////////////////////////////////
            anno1ScreenCoords = Vector3.Project(
                Vector3.Zero(), _dummyIndic.getWorldMatrix(),
                scene.getTransformMatrix(),
                mainCamera.viewport.toGlobal(engine.getRenderWidth(true), engine.getRenderHeight(true))

            );

            anno2ScreenCoords = Vector3.Project(
                Vector3.Zero(), _anotherDummyIndic.getWorldMatrix(),
                scene.getTransformMatrix(),
                mainCamera.viewport.toGlobal(engine.getRenderWidth(true), engine.getRenderHeight(true))

            );


            newAnno.annotation.style.transform = "translate3d(calc(" + (anno1ScreenCoords.x) + "px - 100%), calc(" + (anno1ScreenCoords.y) + "px - 100%), 10px)";

            newAnno2.annotation.style.transform = "translate3d(calc(" + (anno2ScreenCoords.x) + "px - 100%), calc(" + (anno2ScreenCoords.y) + "px - 100%), 10px)";


        });

        /////////////////////////////
        //ACTION MANAGER PROTOTYPE //
        /////////////////////////////
        var dragged = false;
        //Detects mouse up
        canvas.addEventListener("pointerup", function () {
            console.log("Mouse UP!");
            dragged = false;
        });

        //Detects mouse down
        canvas.addEventListener("pointerdown", function () {
            console.log("Mouse DOWN!");
            dragged = true;
        });

        //Detects click
        canvas.addEventListener("click", function () {
            console.log("Mouse Click!");
        });

        const setVisible = (value: boolean) => {
            _dummyIndic.isVisible = value;
            _anotherDummyIndic.isVisible = value;
        };

        //INDIC ACTIONS MANAGER 
        _dummyIndic.actionManager = new ActionManager(scene);
        _dummyIndic.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function () {
            // Piwik PRO analytics tag
            // @ts-ignore
            _paq.push(["trackEvent", 'Dummy indic clicked', 'Main']);
            newAnno.annotation.classList.remove("is-hidden");
            setVisible(false);
            changeCamera(mainCamera, scene, dummyIndic, null)
            dummyIndicText.textPlane.visibility = 0

        }));
        _anotherDummyIndic.actionManager = new ActionManager(scene);
        _anotherDummyIndic.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function () {

            // Piwik PRO analytics tag
            // @ts-ignore
            _paq.push(["trackEvent", 'anotherDummy indic clicked', 'Main']);
            newAnno2.annotation.classList.remove("is-hidden");
            console.log("clicked")
            setVisible(false);
            changeCamera(mainCamera, scene, anotherDummyIndic, null);
            anotherDummyIndicText.textPlane.visibility = 0

        }));

        /// ANNOTATIONS ACTION MANAGER 
        newAnno.closeBtn.onclick = () => {
            // Piwik PRO analytics tag
            // @ts-ignore
            _paq.push(["trackEvent", 'anno clicked', 'Main']);

            newAnno.annotation.classList.add("is-hidden");
            setVisible(true);
            changeCamera(mainCamera, scene, zeroPoint, null)
            dummyIndicText.textPlane.visibility = 1

        }
        newAnno2.closeBtn.onclick = () => {
            // Piwik PRO analytics tag
            // @ts-ignore
            _paq.push(["trackEvent", 'anno2 clicked', 'Main']);

            newAnno2.annotation.classList.add("is-hidden");
            setVisible(true);
            changeCamera(mainCamera, scene, zeroPoint, null)
            anotherDummyIndicText.textPlane.visibility = 1

        }

        //MESH ACTION MANAGER AND ANIMATIONS 

        //box action manager for test, delete later 
        box.actionManager = new ActionManager(scene);
        box.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function () {
            Animation.CreateAndStartAnimation("anim", box, "position", 33, 100, new Vector3(0, 3, 0), new Vector3(2, 3, 4), Animation.ANIMATIONLOOPMODE_CONSTANT, null, () => {
                backButton.classList.remove("is-hidden");
            });

        }));

        backButton.onclick = () => {
            backButton.classList.add("is-hidden");
            Animation.CreateAndStartAnimation("anim", box, "position", 33, 100, new Vector3(2, 3, 4), new Vector3(0, 3, 0), Animation.ANIMATIONLOOPMODE_CONSTANT)
        }














        return scene;
    };
    public get createScene() {
        return this._createScene;
    }
    public set createScene(value) {
        this._createScene = value;
    }

}

export default new AppModeController();


