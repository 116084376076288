import { Engine, Scene, Tools } from "@babylonjs/core";

import FooterLogo from "./toolbox-icons/FooterLogo.png"
import photo from "./toolbox-icons/photo.png"
import quality_HI from "./toolbox-icons/quality_HI.png"
import share from "./toolbox-icons/share.png"


export function setupToolbox( scene:Scene) {
    // const path = require("path");
    // const close_icon = path.join(__dirname, "../src/scene/toolbox-icons")

    const toolboxViewString = `
    <div id="bottom-bar">
        <div class="footer">
            <div class="footer-logo-link" id="footerLogoLink">
                <img src=${FooterLogo} alt="Powered by 3ngage" />
            </div>
            <p class="footer-more-info" id="footerMoreInfo" style="visibility: hidden; opacity: 0;">
                <span>powered by <a id="footerLogoLinkAhref" href="https://3ngage.se" target="_blank"><strong>3ngage</strong></a></span>
            </p>
        </div>

        <div class="toolbox-cont" id="toolboxCont">
            <div class="toolbox-btn" id="shareBtn"><img src=${share} alt="Powered by 3ngage" /></div>
            <div class="toolbox-btn" id="settingsBtn"><img src=${quality_HI} alt="Powered by 3ngage" /></div>
            <div class="toolbox-btn" id="photoModeBtn"><img src=${photo} alt="Powered by 3ngage" /></div>

        </div>

        <div class="toolbox-menu" id="settingsMenu">
            <div class="toolbox-menu-item radio active" id="settingsMenuHiBtn">High Resolution</div>
            <div class="toolbox-menu-item radio" id="settingsMenuLoBtn">Low Resolution</div>
        </div>
        <div class="toolbox-menu" id="shareMenu">
            <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
            <a id="click" class="a2a_dd" href="https://www.addtoany.com/share"></a>
            <a id="click" class="a2a_button_facebook"></a>
            <a  id="click"class="a2a_button_twitter"></a>
            <a  id="click"class="a2a_button_email"></a>
            <a  id="click"class="a2a_button_linkedin"></a>
            <a  id="click"class="a2a_button_facebook_messenger"></a>
            <a  id="click"class="a2a_button_whatsapp"></a>
            </div>
            
        </div>
        <div class="toolbox-menu" id="photoModeMenu">
            <div class="toolbox-menu-item radio">Width: <input type="number" min="100" id="inputWidth"></input></div>
            <div class="toolbox-menu-item radio">Height:<input  type="number" min="100" id="inputHeight"></input></div>
            <div class="toolbox-menu-item radio" id="photoModeClick">Capture Moment</div>
        </div>
    </div> `;
    const toolboxView = document.createElement("div");
    toolboxView.innerHTML = toolboxViewString;
    document.body.appendChild(toolboxView)
////////////
    // FOOTER //
    ////////////

    const footerLogoLink = document.querySelector("#footerLogoLink") as HTMLElement;
    const footerLogoLinkAhref = document.querySelector("#footerLogoLinkAhref") as HTMLElement;
    const footerMoreInfo = document.querySelector("#footerMoreInfo") as HTMLElement;

    const setupFooter=()=> {

       footerLogoLink.addEventListener("click",onFooterLogoLinkClick);
       footerLogoLinkAhref.addEventListener("click",onFooterLogoLinkAhrefClick);
    }

    let footerMoreInfoVisible = false;

    const onFooterLogoLinkClick = (e: MouseEvent) => {
        if (footerMoreInfoVisible) {
           footerMoreInfoVisible = false;
           footerMoreInfo.style.visibility = "hidden";
           footerMoreInfo.style.opacity = "0";
           
        } else {
           footerMoreInfoVisible = true;
           footerMoreInfo.style.visibility = "visible";
           footerMoreInfo.style.opacity = "1";
            e.preventDefault();
           
        }
    };

    const onFooterLogoLinkAhrefClick = (e: MouseEvent) => {
      
    };
    ///////////
    // MENUS //
    ///////////

    let menuActive: HTMLElement = null;

    ///////////////////
    // SETTINGS MENU //
    ///////////////////

    const settingsBtn = document.querySelector("#settingsBtn") as HTMLElement;
    const settingsBtnIco = document.querySelector("#settingsBtn img") as HTMLImageElement;
    const settingsMenu = document.querySelector("#settingsMenu") as HTMLElement;
    const settingsMenuHiBtn = document.querySelector("#settingsMenuHiBtn") as HTMLElement;
    const settingsMenuLoBtn = document.querySelector("#settingsMenuLoBtn") as HTMLElement;

    const setupSettingsMenu = () => {

        settingsBtn.addEventListener('click', () => {
            if (menuActive && menuActive != settingsMenu) {
                menuActive.classList.remove("visible");
                menuActive = null;
            }

            if (menuActive == settingsMenu) {
                settingsMenu.classList.remove("visible");
                menuActive = null;
            } else {
                settingsMenu.classList.add("visible");
                menuActive = settingsMenu;
            }
        });

        let hideSettingsMenuDelay = () => {
            setTimeout(() => {
                if (menuActive == settingsMenu) {
                    settingsMenu.classList.remove("visible");
                    menuActive = null;
                }
            }, 50);
        };

        settingsMenuHiBtn.addEventListener('click', () => {

            settingsMenuHiBtn.classList.add("active");
            settingsMenuLoBtn.classList.remove("active");
            hideSettingsMenuDelay();
        });
        settingsMenuLoBtn.addEventListener('click', () => {

            settingsMenuHiBtn.classList.remove("active");
            settingsMenuLoBtn.classList.add("active");
            hideSettingsMenuDelay();
        });

        window.addEventListener("resize", () => {
            /////
        });
    }

 



    ///////////////
    ///PHOTOMODE//
    //////////////

    const photoModeBtn = document.querySelector("#photoModeBtn") as HTMLElement;
    const photoModeIco = document.querySelector("#photoModeBtn img") as HTMLImageElement;

    const photoModeMenu = document.querySelector("#photoModeMenu") as HTMLElement;
    const photoModeClick = document.querySelector("#photoModeClick") as HTMLElement;
    const inputWidth = document.querySelector("#inputWidth") as HTMLInputElement;
    const inputHeight = document.querySelector("#inputHeight") as HTMLInputElement;


    const setupPhotoModeMenu = () => {

        let saveNow3: number;

        let engine :Engine;

        photoModeBtn.addEventListener('click', () => {

            if (menuActive && menuActive != photoModeMenu) {
                menuActive.classList.remove("visible");
                menuActive = null;
            }

            if (menuActive == photoModeMenu) {
                photoModeMenu.classList.remove("visible");
                menuActive = null;
            } else {
                photoModeMenu.classList.add("visible");
                menuActive = photoModeMenu;
            }
        });

        let hideSettingsMenuDelay = () => {
            setTimeout(() => {
                if (menuActive == photoModeMenu) {
                    photoModeMenu.classList.remove("visible");
                    menuActive = null;
                }
            }, 50);
        };


        photoModeClick.addEventListener('click', () => {
            // console.log("dimenions",_inputWidth.valueAsNumber,_inputHeight.valueAsNumber)

            saveNow3 = 1
            hideSettingsMenuDelay();
        });
        scene.getEngine().onEndFrameObservable.add(function () {
            if (saveNow3 === 1) {
                saveNow3 = 0;
                Tools.CreateScreenshotUsingRenderTarget(
                    scene.getEngine(),
                    scene.activeCamera,
                    {
                        width: inputWidth.valueAsNumber,
                        height: inputHeight.valueAsNumber
                    },
                    undefined,
                    'image/png',
                    1,
                    false,
                    'screenshot.png'
                );
            }
        });



        // window.addEventListener("resize", () => {
        //     viewer.refreshCurrHslHiActive();
        // });
    }

    /////////
    //SHARE//
    /////////

    const shareModeBtn = document.querySelector("#shareBtn") as HTMLElement;
    // const photoModeIco = document.querySelector("#photoModeBtn img") as HTMLImageElement;

    const shareModeMenu = document.querySelector("#shareMenu") as HTMLElement;
    const clickShareBTn = document.querySelectorAll("#click");



    const setupShareModeMenu = () => {





        shareModeBtn.addEventListener('click', () => {

            if (menuActive && menuActive != shareModeMenu) {
                menuActive.classList.remove("visible");
                menuActive = null;
            }

            if (menuActive == shareModeMenu) {
                shareModeMenu.classList.remove("visible");
                menuActive = null;
            } else {
                shareModeMenu.classList.add("visible");
                menuActive = shareModeMenu;
            }
        });

        let hideSettingsMenuDelay = () => {
            setTimeout(() => {
                if (menuActive == photoModeMenu) {
                    photoModeMenu.classList.remove("visible");
                    menuActive = null;
                }
            }, 50);
        };
    }

    setupPhotoModeMenu();
    setupShareModeMenu();
    setupSettingsMenu();
    setupFooter();
}