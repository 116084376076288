import { Mesh, MeshBuilder, Scene } from "@babylonjs/core";
import {
    AdvancedDynamicTexture,
    StackPanel,
    Button,
    TextBlock,
    Rectangle,
    Checkbox,
    Control,
    StackPanel3D,
    Grid,
    XmlLoader,
    RadioButton,
} from '@babylonjs/gui';

export const textInHotspot = (TextContent:string,ParentIndic:Mesh,Fontsize:number,scene:Scene) =>{
    let textPlane:Mesh;
    let textBlock;
    textPlane = MeshBuilder.CreatePlane("text", { size: 0.5 }, scene)
    textPlane.position = ParentIndic.position
    textPlane.visibility = 1;
    textPlane.isPickable = false
    let advancedTexture = AdvancedDynamicTexture.CreateForMesh(textPlane);
    textBlock = new TextBlock();
    textBlock.text = TextContent;
    textBlock.color = "white";
    textBlock.fontSize = Fontsize;
    advancedTexture.addControl(textBlock)
    textPlane.billboardMode = 7;
    console.log(textPlane.position)

    return{
        'textPlane': textPlane,
    }
}